import ComputePriceConcern from 'controllers/concerns/compute_price_concern.js.erb'

export default class extends ComputePriceConcern {
    initialize() {
        if($('.booking_start_date .flatpickr-calendar').length > 1 && $('.booking_end_date .flatpickr-calendar').length > 1) {
            document.location.reload(true)
        }

        this.bookingDataParams()
        this.displayClientDetailsForm()
        this.fetchApiJsonPrice()
        this.setCalendarDates()
        $('#booking_adults').on('blur change keyup paste', () => { this.initClassVariablesAndFetchApi() })
        $('#booking_children_under_2').on('blur change keyup paste', () => { this.initClassVariablesAndFetchApi() })
        $('#booking_children_under_12').on('blur change keyup paste', () => { this.initClassVariablesAndFetchApi() })
    }

    initClassVariablesAndFetchApi() {
        this.no_of_adults = $('#booking_adults')[0].value
        this.no_of_children_under2 = $('#booking_children_under_2')[0].value
        this.no_of_children_under12 = $('#booking_children_under_12')[0].value
        this.fetchApiJsonPrice()
    }

    fetchApiJsonPrice() {
        if (!(this.no_of_adults || this.no_of_children_under2 || this.no_of_children_under12)) {
            this.no_of_adults = 1
        }

        let data = {
            no_of_adults: $('#booking_adults')[0].value || this.no_of_adults,
            no_of_children_under2: this.no_of_children_under2,
            no_of_children_under12: this.no_of_children_under12,
            start_date: $('#booking_start_date')[0].value,
            end_date: $('#booking_end_date')[0].value,
            villa_id: $('#booking_villa_id')[0].value,
        }

        const options = {
            method: `POST`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        }

        $.post('/compute_villa_prices.json', options, (jsonData) => {
            this.displayPricesAndTotal(jsonData)
        })
    }
}