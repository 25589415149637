import { Controller } from 'stimulus'
export default class extends Controller {
    initialize() {
        $('.switch-view .options .type').on('click', function(e) {
            $('.switch-view .options .type').removeClass('active')
            var clicked_parent = $(this)
            if (e.target !== this) {
                clicked_parent = $(e.target).parent('.type')
            }
            clicked_parent.addClass('active')
            $('.property-wrapper .property').removeClass().addClass('property').addClass(clicked_parent.data('classes'))

            $('.pagination a').each(function() {
                var updatedHref = $(this).attr('href')
                    .replace(/(view_type=)([a-z_]+)/, '$1' + clicked_parent.data('type'))
                    .replace(/(view\/)([a-z_]+)/, '$1' + clicked_parent.data('type'))
                $(this).attr('href', updatedHref)
            })
            $.ajax({
                method: 'GET',
                url: clicked_parent.data('url') + '?view_type=' + clicked_parent.data('type')
            })
        })
    }
}