// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "jquery"
import '../js/bootstrap_js_files.js'
import 'controllers'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import './flatpickr'

const images = require.context('../images', true)
// css stuff, needed due to webpacker particularities
import '../styles/application.scss'
import "lightgallery.js/dist/css/lightgallery.min.css"

window.dispatchMapsEvent = function (...args) {
    const event = document.createEvent("Events")
    event.initEvent("google-maps-callback", true, true)
    event.args = args
    window.dispatchEvent(event)
}

import './cookies_eu_banner'