import { Controller } from 'stimulus'

export default class extends Controller {
    initialize() {
        let params = decodeURIComponent(window.location.search).split('?q')[1]
        if(params) {
            let search_params = params.split('&q')
            let dates = search_params.map( param => param.split('&').filter(p => p.includes('start_date') || p.includes('end_date')))
            this.start_date = new Date(dates[0][0].split('=')[1]) || 'today'
            this.end_date = new Date(dates[1][0].split('=')[1]) || new Date().setDate(new Date().getDate() + 1)
        } else {
            this.start_date = 'today'
            this.end_date = new Date().setDate(new Date().getDate() + 1)
        }

        document.querySelector("#q_end_date").flatpickr({
            minDate: new Date().setDate(new Date().getDate() + 1),
            defaultDate: this.end_date,
            maxDate: new Date().setFullYear(new Date().getFullYear() + 1),
            prevArrow: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.65 25"><defs><style>.cls-2{fill:#808080;}</style></defs><title>previous_arrow</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><polygon class="cls-2" points="0 12.5 21.65 25 21.65 0 0 12.5"/></g></g></svg>',
            nextArrow: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.65 25"><defs><style>.cls-1{fill:#fff;}</style></defs><title>next_arrow</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><polygon class="cls-1" points="21.65 12.5 0 0 0 25 21.65 12.5"/></g></g></svg>',
            monthSelectorType: 'static'
        })

        document.querySelector("#q_start_date").flatpickr({
            minDate: "today" ,
            defaultDate: this.start_date,
            maxDate: new Date().setFullYear(new Date().getFullYear() + 1),
            prevArrow: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.65 25"><defs><style>.cls-2{fill:#808080;}</style></defs><title>previous_arrow</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><polygon class="cls-2" points="0 12.5 21.65 25 21.65 0 0 12.5"/></g></g></svg>',
            nextArrow: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.65 25"><defs><style>.cls-1{fill:#fff;}</style></defs><title>next_arrow</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><polygon class="cls-1" points="21.65 12.5 0 0 0 25 21.65 12.5"/></g></g></svg>',
            monthSelectorType: 'static'
        })
    }
}