import { Controller } from 'stimulus'
import I18n from "../packs/i18n.js.erb";

export default class extends Controller {
    initialize() {
        if (!($('.booking_start_date .flatpickr-calendar').length >= 1 && $('.booking_end_date .flatpickr-calendar').length >= 1)) {
            this.already_booked_days = this.element.dataset.booked.split(',')
            this.initial_selection_start = this.element.dataset.initialSelectionStart
            this.initial_selection_end = this.element.dataset.initialSelectionEnd
            let eligible_next_week =
                this.initial_selection_start ? this.initial_selection_start : new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
            let eligible_period_after_next_week =
                this.initial_selection_end ? this.initial_selection_end : new Date(Date.now() + 17 * 24 * 60 * 60 * 1000)

            let second_picker = document.querySelector("#booking_end_date").flatpickr({
                minDate: new Date().setDate(new Date().getDate() + 1),
                defaultDate: eligible_period_after_next_week,
                maxDate: new Date().setFullYear(new Date().getFullYear() + 1) - 86400000, // 86400000 is 1 day in ms
                disable: this.already_booked_days,
                inline: true,
                prevArrow: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.65 25"><defs><style>.cls-2{fill:#808080;}</style></defs><title>previous_arrow</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><polygon class="cls-2" points="0 12.5 21.65 25 21.65 0 0 12.5"/></g></g></svg>',
                nextArrow: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.65 25"><defs><style>.cls-1{fill:#fff;}</style></defs><title>next_arrow</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><polygon class="cls-1" points="21.65 12.5 0 0 0 25 21.65 12.5"/></g></g></svg>',
                monthSelectorType: 'static',
                onChange: (_dateObj, _dateStr) => {
                    $('#submit_choose_date').text(I18n.t('booking.choose_date.select'))
                    $('#submit_choose_date').append(' <i class="cust-i right-w"></i>')

                    $('.flatpickr-day.inRange').removeClass('inRange')
                    let start_date = new Date($('.booking_start_date .flatpickr-day.selected')[0].getAttribute("aria-label"))
                    let end_date = new Date($('.booking_end_date .flatpickr-day.selected')[0].getAttribute("aria-label"))
                    let dates_arr = []
                    let current_date = start_date

                    while (current_date <= end_date) {
                        dates_arr.push(new Date(current_date))
                        current_date.setUTCDate(current_date.getUTCDate() + 1)
                    }

                    if (dates_arr.length !== 0) {
                        let parsed_first_date = dates_arr[0].toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                        })
                        $(`.booking_start_date .flatpickr-day[aria-label="${parsed_first_date}"]`).addClass('selected')
                        $(`.booking_end_date .flatpickr-day[aria-label="${parsed_first_date}"]`).addClass('selected')
                        dates_arr.shift()
                        dates_arr.forEach((date) => {
                            let parsed_date = date.toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                            })
                            $(`.booking_start_date .flatpickr-day[aria-label="${parsed_date}"]`).addClass('inRange')
                            $(`.booking_end_date .flatpickr-day[aria-label="${parsed_date}"]`).addClass('inRange')
                        })
                    }
                }
            })

            document.querySelector("#booking_start_date").flatpickr({
                minDate: "today",
                defaultDate: eligible_next_week,
                maxDate: new Date().setFullYear(new Date().getFullYear() + 1) - 86400000, // 86400000 is 1 day in ms
                disable: this.already_booked_days,
                inline: true,
                prevArrow: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.65 25"><defs><style>.cls-2{fill:#808080;}</style></defs><title>previous_arrow</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><polygon class="cls-2" points="0 12.5 21.65 25 21.65 0 0 12.5"/></g></g></svg>',
                nextArrow: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.65 25"><defs><style>.cls-1{fill:#fff;}</style></defs><title>next_arrow</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><polygon class="cls-1" points="21.65 12.5 0 0 0 25 21.65 12.5"/></g></g></svg>',
                monthSelectorType: 'static',
                onChange: (dateObj, dateStr) => {
                    $('#submit_choose_date').text(I18n.t('booking.choose_date.select'))
                    $('#submit_choose_date').append(' <i class="cust-i right-w"></i>')

                    second_picker.set("minDate", dateStr)
                    second_picker.setDate(dateStr)
                }
            })

            $('.booking_end_date .selected').click()
        }
    }
}