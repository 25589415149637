import { Controller } from 'stimulus'
import I18n from "../../packs/i18n.js.erb";

export default class extends Controller {
    displayPricesAndTotal(jsonData) {
        let sum = 0
        $('#prices').empty('')
        jsonData.forEach((data) => {
            sum += parseFloat(this.displayVillaPricesAndReturn(data))
        })
        $('#total_price').html($('#total_price').html().replace(/[+-]?([0-9]*[.])?[0-9]+/g, sum))
    }

    checkAvailability() {
        let already_booked_days = $('#already_booked_days').attr('data-booked').replace(/\[|\]/g, '').split(',')
        let booking_start_date = new Date($('.booking_start_date .flatpickr-day.selected')[0].getAttribute("aria-label"))
        let booking_end_date = new Date($('.booking_end_date .flatpickr-day.selected')[0].getAttribute("aria-label"))

        if(booking_start_date.valueOf() == booking_end_date.valueOf()) {
            booking_end_date = new Date($('.booking_end_date .flatpickr-day.selected')[1].getAttribute("aria-label"))
        }

        let booked_periods = already_booked_days.map((already_booked_period) => {
            already_booked_period = new Date(already_booked_period)
            return !(already_booked_period >= booking_start_date && already_booked_period <= booking_end_date)
        })

        return booked_periods.indexOf(false) == -1
    }

    displayClientDetailsForm() {
        $('#submit_choose_date').on('click', (e) => {
            e.stopPropagation()
            e.preventDefault()

            this.fetchApiJsonPrice()
            if (this.checkAvailability()) {
                $('#submit_choose_date').text(I18n.t('booking.choose_date.select'))
                $('#submit_choose_date').append(' <i class="cust-i right-w"></i>')
                $('#booking_form.hidden').css('display', 'block')
                $('input[type="hidden"][id="booking_start_date"][class="client_booking_start_date"]')[0].value = $('#choose-date #booking_start_date')[0].value
                $('input[type="hidden"][id="booking_end_date"][class="client_booking_end_date"]')[0].value = $('#choose-date #booking_end_date')[0].value
                $('#already_booked_days')
                this.changeDateOnCalendar()
            } else {
                $('#booking_form').css('display', 'none')
                $('#submit_choose_date').text(I18n.t('activerecord.errors.models.booking.attributes.period_unavailable'))
            }
        })
    }

    changeDateOnCalendar() {
        $('span.flatpickr-day:not(.flatpickr-disabled)').on('click', (_e) => {
            $('#booking_form').css('display', 'none')
        })
    }

    displayBookingShow(e) {
        e.preventDefault()
        $('#booked_done.hidden').css('display', 'block')
        $('#booking_form').css('display', 'none')
        $('#choose-date').css('display', 'none')

        setTimeout(() => {
            $("#booking_form_form").submit()
        }, 600)
        return true
    }

    displayVillaPricesAndReturn(data) {
        let price = parseFloat(data[1])
        if (price != 0.0) {
            $('#prices').append('<b>' + I18n.t(`booking.summary.${data[0]}`) + ': </b>' + `${price} CHF<br>`)
        }
        return price
    }

    bookingDataParams() {
        let booking_start_date_value = $('input[type="hidden"][id="booking_start_date"][class="client_booking_start_date"]')[0].value
        let booking_end_date_value = $('input[type="hidden"][id="booking_end_date"][class="client_booking_end_date"]')[0].value
        if(booking_start_date_value) {
            if ($('#booking_start_date')[0].value != booking_start_date_value) { $('#booking_start_date')[0].value = booking_start_date_value }
        }

        if(booking_end_date_value) {
            if ($('#booking_end_date')[0].value != booking_end_date_value) { $('#booking_end_date')[0].value = booking_end_date_value }
        }
    }

    setCalendarDates() {
        let booking_start_date = $('input[type="hidden"][id="booking_start_date"][class="client_booking_start_date"]')[0].value
        let booking_end_date = $('input[type="hidden"][id="booking_end_date"][class="client_booking_end_date"]')[0].value

        if(booking_start_date) {
            document.querySelector("#booking_start_date")._flatpickr.setDate(booking_start_date)
        }
        if(booking_end_date) {
            document.querySelector("#booking_end_date")._flatpickr.setDate(booking_end_date)
        }
    }
}