import { Controller } from 'stimulus'
export default class extends Controller {
    initialize() {
        $.noConflict()
        $(document).ready(() => {
            setTimeout(() => {
                if (typeof (google) != "undefined") {
                    this.initMap()
                }
            }, 300)
        })
    }

    initMap() {
        const map = new google.maps.Map(document.getElementById("map"), {
            zoom: 12,
            center: { lat: 46.15, lng: 8.816667 },
        })

        $.getJSON('/geocodes/index.json', (jsonData) => {
            jsonData.forEach((data) => {
                let myLatlng = new google.maps.LatLng(parseFloat(data.latitude), parseFloat(data.longitude))

                let marker = new google.maps.Marker({
                    position: myLatlng,
                    map,
                    url: `/villa/${data.villa_slug}`,
                    title: `Villa ${data.villa_name}`,
                })
                marker.setMap(map)

                google.maps.event.addListener(marker, 'click', (e) => {
                    window.location.href = marker.url
                })
            })
        })
    }
}